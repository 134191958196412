//@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+KR');
//@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic+Coding');
//@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic');
//@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR');
//@import url('https://fonts.googleapis.com/css?family=Jua');

@font-face {
  font-family: 'IBM Plex Sans KR';
  font-weight: normal;
  //font-display: swap;
  src: url('./assets/fonts/IBMPlexSansKR-Regular.ttf') format('truetype');
}

*:not(.pi) {
  font-family: 'IBM Plex Sans KR', var(--font-family) !important;
  //font-family: "Nanum Gothic Coding", Roboto, serif;
  //font-family: "Nanum Gothic", Roboto, serif;
  //font-family: "Noto Sans KR", Roboto, serif;
  //font-family: "BM Jua", Roboto, serif;
}

:root {
  --red-50: #fff5f5;
  --red-100: #ffd1ce;
  --red-200: #ffada7;
  --red-300: #ff8980;
  --red-400: #ff6459;
  --red-500: #ff4032;
  --red-600: #d9362b;
  --red-700: #b32d23;
  --red-800: #8c231c;
  --red-900: #661a14;
  --skyblue: #75c9e9;
  --gray-bg: #f5f5f5;
  --gray-border: #dadada;
  --gray-dark: #303030;
  --black: #272727;
}

/* dropdown ovverride */

.p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
}

/* radio button override */

.p-radiobutton .p-radiobutton-box.p-highlight {
  position: relative;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  width: 70%;
  height: 70%;
  transition: transform 0.5s;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  // transform-origin: center;
  transform: translate(-50%, -50%);
}

/* browser auto complete background reset */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label {
  cursor: pointer;
}

/* browser auto complete background reset */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* radio button override */

.p-radiobutton .p-radiobutton-box.p-highlight {
  position: relative;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  width: 70%;
  height: 70%;
  transition: transform 0.5s;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  // transform-origin: center;
  transform: translate(-50%, -50%);
}

.cm-stamp img {
  width: 37px;
  height: 37px;
}

.cm-stamp .p-image-preview-indicator {
  height: 37px;
}

.cm-no-border {
  border: 0 !important;
}

.cm-dialog-content {
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
}

.cm-inner-panel {
  .p-panel-header {
    padding: 0.6rem;
  }
}

.cm-pcform-table {
  width: 100%;
  min-width: 1808px;
  table-layout: fixed;
  border: 1px solid var(--gray-400);

  .left {
    width: 900px;

    table {
      table-layout: fixed;

      th {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }
    }
  }

  .splitter {
    width: 8px;
    //background-color: var(--gray-400);
  }

  .right {
    width: 900px;

    table {
      table-layout: fixed;

      th {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }
    }
  }

  //tbody {
  //  th {
  //    width: 100px;
  //    background-color: #e9ecef;
  //    border: 1px solid #ced4da;
  //    border-radius: 2px;
  //  }
  //
  //  td {
  //    width: 200px;
  //    padding: 0 4px;
  //  }
  //}
}

.p-fileupload {
  .p-fileupload-content {
    padding: 0.5rem;
  }
}

.p-image-toolbar {
  z-index: inherit;
}

.p-panel {
  .p-panel-title {
    width: 100% !important;
  }

  .p-panel-content {
    padding: 1rem;
  }
}

.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@page {
  size: A4 portrait;
  margin: 0 !important;
  padding: 0 !important;
}

.pcr_wrapper {
  min-width: 210mm;
}

.pcr {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;

  .paper_container {
    margin-bottom: 10px;
    background: white;
  }

  .paper {
    position: relative;
    width: 210mm;
    height: 297mm;
    min-height: 297mm;
    padding: 1rem 1.8rem !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    position: relative !important;
    border-spacing: 0;

    &.paper-main {
      padding: 1rem 4rem !important;
    }

    .main-logo {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;

      .main-logo-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background: url('./assets/images/handok-logo-white.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      p {
        font-size: 2rem;
        font-weight: 600;
      }
    }

    .content {
      border-width: 2px !important;
      border-style: solid !important;
      border-spacing: 0;
      padding: 0;

      .cbi {
        width: 12px;
        height: 12px;
      }

      .cbib {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.p-galleria-item-next-icon,
.p-galleria-item-prev-icon {
  color: black;
}

.p-galleria-item-nav {
  top: 300px;
  z-index: 1;
}

.p-galleria-thumbnail-container {
  z-index: 1;
}

.p-galleria-content {
  display: flex;
  justify-content: center;
}

#claim-dialog .p-card-title,
.p-panel-title {
  font-size: 1.1rem;
}

#claim-dialog .p-card-content {
  padding-bottom: 0px;
}

#claim-dialog,
#note .p-panel-content {
  height: 90vh;
}

.dashboard-h {
  height: calc(100vh - 9rem);
}

.input-border-primary {
  background: var(--primary-color);
  color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0.5rem 0.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
}

#pcr .shadow-2 {
  box-shadow: none !important;
  border: 1px solid var(--surface-200);
  border-radius: 4px;
}

.cbi {
  width: 12px;
  height: 12px;
}

.cbib {
  width: 16px;
  height: 16px;
}

// new 성능지
.pcr {
  font-size: 11px;

  .watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/icons/logo-handok.webp') center center no-repeat;
    background-size: 500px;
    opacity: 0.05;
    /* 워터마크 투명도 */
    pointer-events: none;
    /* 워터마크에 마우스 이벤트를 막기 위해 */
    z-index: 0;
  }

  .section_title_new {
    font-size: 16px;
    font-weight: bold;
    color: var(--black);
    border-bottom: 2px solid var(--black);

    margin-top: 20px;
    margin-bottom: 1.5rem;

    h4 {
      font-weight: bold;
    }
  }

  .section_title_gray_new {
    font-size: 14px;
    font-weight: bold;
    color: var(black);
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0 0.5rem;

    h5 {
      font-weight: bold;
    }
  }

  .table {
    width: 100%;
    color: black;
    font-size: 11px;

    td {
      padding: 12px 16px;
    }

    .title {
      background-color: var(--gray-bg);
    }
  }

  .table_narrow {
    td {
      padding: 10px 16px;
    }
  }

  table,
  th,
  td {
    border: 1px solid var(--gray-border);
    border-collapse: collapse;
  }

  .frame-image {
    position: absolute;
    width: 100%;
    height: auto;
    aspect-ratio: 210 / 297;
    top: 0;
    right: 0;
    background-image: url('./assets/images/handok_cover.png');
    background-size: cover;
    /* 워터마크에 마우스 이벤트를 막기 위해 */
  }

  .hyundai {
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px;

    h1 {
      font-size: 15px;
      font-weight: bold;
      color: #1c204d;
      // background-image: url('./assets/images/insurance/GUIDE_0009_Header.png');
      height: 50px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 12px;
      font-weight: bold;
      color: #1c204d;
      border-bottom: 1px solid #1c204d;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    h5 {
      padding-top: 20px;
      font-size: 12px;
      margin: 0;
      margin-bottom: 10px;
    }

    table {
      font-size: 11px;
      width: 100%;
      margin: 10px 0;

      th,
      td {
        border: 1px solid var(--gray-border);
        border-collapse: collapse;
        padding: 8px;
      }

      th {
        background-color: var(--gray-bg);
        font-weight: bold;
        text-align: center;
      }

      td:first-child {
        text-align: center;
      }

      td {
        text-align: left;
      }
    }

    p {
      font-size: 11px;
      margin-left: 10px;
      margin-bottom: 5px;
    }

    ol,
    ul {
      font-size: 11px;
      margin-top: 0;
      margin-left: 0;
      padding-left: 25px;
    }

    li {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .section_container {
    width: 100%;
    border: 1px solid var(--gray-border);
    margin: 0 auto;
    padding: 10px 20px;
  }

  .section_container_noborder {
    width: 100%;
    margin: 0 auto;
    padding: 10px 10px;
  }

  .paper_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--black);
    color: white;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    background-image: url('./assets/icons/logo-handok.webp');
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: 640px -20px;
    background-blend-mode: multiply;

    &.mini {
      margin-bottom: 10px;
    }

    div {
      display: flex;
      gap: 0.5rem;
      align-content: center;
    }

    span {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 21px;
      font-weight: bold;
      line-height: 1.6;
    }
  }

  .certificate {
    color: black;
    text-align: center;
    margin: 50px auto;
    width: 80%;
    max-width: 600px;
    font-size: 14px;

    .date p {
      margin: 0;
    }

    .subtext {
      margin-top: 5px;
    }
  }
}

.p-button {
  color: #ffffff;
}

.p-button.p-button-outlined {
  color: #2196f3;
}

.p-tag {
  background: #2196f3;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  color: #495057;
}
